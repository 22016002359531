import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import "./ModalEdition.css";
import { Controller, useForm } from "react-hook-form";
import {
  getManagementTypes,
  getProcedureTypes,
  getTaxesTypes,
  getVehicleOrigin,
  updateTask,
} from "../../services/axiosCall";
import Select from "react-select";
import validationIBAN from "../../helpers/validationIBAN";
import OriginType from "../../constants/origin";
import { removeNullFields } from "../../helpers/removeNullFields";
import { errorToast, successToast } from "../../helpers/toastFunction";
import validationPlate from "helpers/validationPlate";

const ModalEdition = ({ show, handleClose, task, type }) => {
  const [taxesTypes, setTaxesTypes] = useState([]);
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [managementTypes, setManagementTypes] = useState([]);
  const [vehicleOrigin, setVehicleOrigin] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    if(show ){
      (async () => {
        if (type === "impuestos") {
          const taxesTypesResponse = await getTaxesTypes();
          setTaxesTypes(taxesTypesResponse);
        }

        if (type === "origen") {
          const vehicleOriginResponse = await getVehicleOrigin();
          setVehicleOrigin(vehicleOriginResponse);
        }
        if (type === "tramite") {
          const response = await getProcedureTypes();
          setProcedureTypes(response);
        }
        if (type === "gestion") {
          const responseTypes = await getManagementTypes();
          setManagementTypes(responseTypes);
        }
      })();
    }
  }, [show, type]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const filteredData = {
        vehiculoFormaPago: data.vehiculoFormaPago || null,
        matriculacion: data.matriculacion || null,
        iban: data.iban || null,
        titular: data.titular || null,
        impuestos: data.impuestos?.value || null,
        origin: data.origin?.value || null,
        tramite: data.tramite?.value || null,
        gestion: data.gestion?.value || null,
      };
      const dataFiltered = removeNullFields(filteredData);
      const body = { ...dataFiltered, id: task.id };

      const result = await updateTask(body);

      if (result) {
        successToast(
          "La modificación de los datos se ha completado correctamente."
        );
      }
      handleClose();
    } catch (error) {
      const errorMessages = {
        409: (
          <>
            <p className="toast-title">No es posible guardar el cambio</p>
            <p className="toast-text">
              Esta matrícula y DNI ya están vinculados a otra gestión
            </p>
          </>
        ),
        default:
          "Ha habido un problema con la edición. Vuelva a intentarlo más tarde y en caso de que el error persista contacte con su administrador.",
      };
      errorToast(errorMessages[error.response.status] || errorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const selectImpuestos = () => {
    const response = taxesTypes;
    if (task?.origin?.value === OriginType.PRIVATE) {
      return [{ label: "REBU", value: 4 }];
    }
    return response;
  };

  const maxDate = (value) => {
    const date = new Date(value);
    const today = new Date();
    return date <= today;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="modal_header">
          <Modal.Title>{`Editar ${type}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {type === "impuestos" && (
              <Row className="mt-4">
                <h6 title="Seleccion tipo de impuesto">Tipo de impuesto:</h6>
                <Col>
                  <Controller
                    name="impuestos"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione impuesto"
                        {...field}
                        options={selectImpuestos()}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.impuestos?.type === "required" && (
                    <span className="error">Seleccione impuesto</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "ajuste" && (
              <Row className="mt-4">
                <h6 title="Introduza ajuste">Importe de ajuste:</h6>
                <Col className="my-1">
                  <input
                    type="number"
                    className={`form-control ${errors.ajuste && "is-invalid"} `}
                    {...register("ajuste", { required: true })}
                  />
                  {errors.ajuste?.type === "required" && (
                    <span className="error">
                      Introduzca el importe de ajuste
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {type === "titular" && (
              <Row className="mt-4">
                <Col className="my-1">
                  <input
                    placeholder="Titular"
                    className={`form-control ${
                      errors.titular && "is-invalid"
                    } `}
                    {...register("titular", { required: true })}
                  />
                  {errors.titular?.type === "required" && (
                    <span className="error">Introduzca el titular</span>
                  )}
                </Col>
              </Row>
            )}
            {type === "vehiculoFormaPago" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${
                      errors.vehiculoFormaPago && "is-invalid"
                    } `}
                    placeholder="Matrícula del vehículo entregado como forma de pago"
                    {...register("vehiculoFormaPago", {
                      required: true,
                      validate: validationPlate,
                    })}
                  />
                  {errors.vehiculoFormaPago?.type === "required" && (
                    <span className="error">
                      Introduzca número de matrícula
                    </span>
                  )}
                  {errors.vehiculoFormaPago &&
                    errors.vehiculoFormaPago?.type !== "required" && (
                      <span className="error">
                        La matrícula del vehículo entregado como forma de pago
                        es incorrecta.
                      </span>
                    )}
                </Col>
              </Row>
            )}
            {type === "matriculacion" && (
              <Row>
                <Col>
                  <input
                    type="date"
                    className={`form-control ${
                      errors.matriculacion && "is-invalid"
                    } `}
                    placeholder="Fecha 1ª matriculación"
                    {...register("matriculacion", {
                      required: true,
                      pattern:
                        /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                      validate: maxDate,
                    })}
                  />
                  {errors.matriculacion?.type === "required" && (
                    <span className="error">
                      Introduzca fecha de la primera matriculación.
                    </span>
                  )}
                  {errors.matriculacion &&
                    errors.matriculacion?.type !== "required" && (
                      <span className="error">
                        Introduzca Fecha de la primera matriculación Válida
                      </span>
                    )}
                </Col>
              </Row>
            )}

            {type === "origen" && (
              <Row>
                <Col>
                  <Controller
                    name="origin"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione origen del vehículo"
                        {...field}
                        options={vehicleOrigin}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.origin?.type === "required" && (
                    <span className="error">
                      Seleccione origen del vehículo
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {type === "iban" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${errors.iban && "is-invalid"} `}
                    placeholder="IBAN"
                    {...register("iban", {
                      required: true,
                      validate: (value) => validationIBAN(value),
                    })}
                  />
                  {errors.iban?.type === "required" && (
                    <span className="error">Introduzca número de IBAN</span>
                  )}
                  {errors.iban && errors.iban?.type !== "required" && (
                    <span className="error">
                      El IBAN introducido es incorrecto
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {type === "tramite" && (
              <Row>
                <Col>
                  <Controller
                    name="tramite"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione tramite"
                        {...field}
                        options={procedureTypes}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.tramite?.type === "required" && (
                    <span className="error">Seleccione un tipo de tramite</span>
                  )}
                </Col>
              </Row>
            )}
            {type === "gestion" && (
              <Row>
                <Col>
                  <Controller
                    name="gestion"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione el tipo de gestión"
                        {...field}
                        options={managementTypes}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.gestion?.type === "required" && (
                    <span className="error">Seleccione un tipo de gestión</span>
                  )}
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <Button className="btn_custom" onClick={handleSubmit(onSubmit)}>
              Guardar
            </Button>
          ) : (
            <Button className="btn_custom" disabled>
              Subiendo...
              <Spinner
                variant="warning"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default ModalEdition;
