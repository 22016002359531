import { Table, Col, Row} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TiDocumentText } from "react-icons/ti";
import { HiDocumentText } from "react-icons/hi";
import { GoAlert } from "react-icons/go";
import { getSignedUrl } from "../../../../services/axiosCall";
import "./TableCollapsible.css";
import { Pago, Taxes } from "../../../../utils/constants/constants";
import RolType from "../../../../constants/roles";
import PaymentState from "../../../../constants/paymentState";
import { useUserContext } from "contexts/UserContext";

const TableCollapsible = ({
  tasks,
}) => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const totalImportHandler = (importe, ajuste, domain) => {
    const number = (importe - (ajuste || 0) - (domain || 0)).toFixed(2);
    let str = number.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); //añade un punto cada 3 cifras
    return str.join(",");
  };

  const openDocumentHandler = async (data) => {
    const response = await getSignedUrl(data);
    window.open(response.signedUrl[0]);
  };

  const localHandler = (localId) => {
    if (user.dealerships) {
      const local = user.dealerships.find((item) => item.value === localId);
      return local?.label;
    }
  };

  const societyHandler = (societyId) => {
    if (user.companiesOptions) {
      const society = user.companiesOptions.find((item) => item.value === societyId);
      return society.label;
    }
  };



  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Tarea</th>
          <th className="date_custom_width">Fecha</th>
          <th>Gestión</th>
          <th>Trámite</th>
          {user.roles?.includes(RolType.CENTRAL_ADMIN || RolType.GENERAL_ADMIN) && (
            <th>Impuestos</th>
          )}
          <th>Origen</th>
          <th>Local</th>
          <th>Sociedad</th>
          <th>Titular</th>
          {user.roles?.includes(RolType.TRADING_AGENT) && <th>Identificación</th>}
          <th>Matrícula</th>
          {user.roles?.includes(RolType.TRADING_AGENT) && <th>Bastidor</th>}
          <th>Reserva Dominio</th>
          <th className="importe_custom_width">Importe</th>
          <th className="pago_custom_width">Pagado</th>
          <th>SAGE</th>
        </tr>
      </thead>
      <tbody>
        {tasks &&
          (tasks?.length > 0
            ? tasks?.map((item) => {
                return (
                  <tr
                    id={item?.id}
                    key={item?.id}
                    data-toggle="collapse"
                    data-target=".multi-collapse1"
                    aria-controls="multiCollapseExample1"
                  >
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.incidencia ? (
                        <GoAlert size={26} color={"red"} />
                      ) : (
                        item.id
                      )}
                    </td>

                    <td
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      className={`${
                        item.incidencia && "incidencia"
                      } cursor_pointer`}
                    >
                      {item.createdAt
                        ?.split("T")[0]
                        ?.split("-")
                        .reverse()
                        .join("/")}
                    </td>

                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.gestion.label}
                    </td>
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.tramite.label}
                    </td>
                    {user.roles?.includes(
                      RolType.CENTRAL_ADMIN || RolType.GENERAL_ADMIN
                    ) && (
                      <td
                        className="cursor_pointer "
                        onClick={() => navigate(`/gestion/${item.id}`)}
                        title={
                          item.impuestos.id === Taxes.IVA_INTRACOMUNITARIO &&
                          "Régimen Especial de Bienes usados en la venta de coches"
                        }
                      >
                        {item.impuestos.label}
                      </td>
                    )}
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.origin.label}
                    </td>
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title={item.society.nombre}
                    >
                      {localHandler(item.local) } 
                    </td>
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {societyHandler(item.society)}
                    </td>
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                    >
                      {item.titular}
                    </td>
                    {user.roles?.includes(RolType.TRADING_AGENT) && (
                      <td
                        className="cursor_pointer "
                        onClick={() => navigate(`/gestion/${item.id}`)}
                        title={item.titular}
                      >
                        {item.numeroIdentificacion}
                      </td>
                    )}
                    <td
                      className="cursor_pointer "
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title={`${item.marca} ${item.modelo}`}
                    >
                      {item.matricula}
                    </td>
                    {user.roles?.includes(RolType.TRADING_AGENT) && (
                      <td
                        className="cursor_pointer "
                        onClick={() => navigate(`/gestion/${item.id}`)}
                      >
                        {item.bastidor}
                      </td>
                    )}
                    <td className="custom_domain_width">
                      {item.domain?.active ? (
                        <Row>
                          <Col>
                            <TiDocumentText
                              className="cursor_pointer"
                              onClick={() =>
                                openDocumentHandler(
                                  item.domain?.cartaFinanciera
                                )
                              }
                              title="Carta financiera"
                              size={26}
                              color={"#007bff"}
                            />

                            {item.domain.informeTrafico ? (
                              <HiDocumentText
                                className="cursor_pointer"
                                onClick={() =>
                                  openDocumentHandler(
                                    item.domain?.informeTrafico
                                  )
                                }
                                title="Informe de tráfico"
                                size={26}
                                color={"#007bff"}
                              />
                            ) : (
                              <HiDocumentText
                                className=""
                                title="Informe de tráfico no disponible"
                                color={"#545454"}
                                size={26}
                              />
                            )}
                          </Col>
                          <Col>
                            <p
                              title={
                                item.domain.estadoPago ===
                                PaymentState.UNPROCESSED
                                  ? "Fecha límite para el pago de la reserva de dominio."
                                  : item.domain.estadoPago ===
                                    PaymentState.SIGNATURE_PENDING
                                  ? "El pago se encuentra en proceso de firma."
                                  : item.domain.estadoPago ===
                                    PaymentState.COMPLETED
                                  ? `Pagado el día ${item.domain.fechaPago}`
                                  : `Vencimiento fecha pago: ${item.domain.fechaLimite
                                      ?.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("/")}`
                              }
                              className={`custom__width__date`}
                            >
                              {item.domain.estadoPago === PaymentState.COMPLETED
                                ? "Pagado"
                                : item.domain?.fechaLimite
                                    ?.split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                            </p>
                          </Col>
                        </Row>
                      ) : item.pago?.id === Pago.SIN_TRAMITAR || !item.pago ? (
                      ""
                      ) : (
                        "No"
                      )}
                    </td>

                    <td
                      className="cursor_pointer"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title="Cantidad total a pagar"
                    >
                      {totalImportHandler(
                        item.importe,
                        item.ajuste,
                        item.domain?.importe
                      )}{" "}
                      €
                    </td>

                    <td
                      className="cursor_pointer"
                      onClick={() => navigate(`/gestion/${item.id}`)}
                      title="Fecha de pago"
                    >
                      {item.fechaPago?.split("-").reverse().join("/")}
                    </td>
                    <td className="success">
                      {item.sage ? (item.sage.status === 1 ? "Enviado" : "" ) : ""}
                    </td>
                  </tr>
                );
              })
            : "No existen resultados que correspondan con la búsqueda.")}
      </tbody>
    </Table>
  );
};

export default TableCollapsible;
