import DocumentationAttachmentType from "./documentation";

const DocumentationRelevance = {
  PRIVATE: {
    MANDATORY: [
      {
        fieldName: "DNI",
        fileType: DocumentationAttachmentType.DNI,
        apiFieldName: "dni",
        editFieldName: "Dni",
        openFieldName: "dniFile",
      },
      {
        fieldName: "Justificante de Gestoría",
        fieldType: DocumentationAttachmentType.CAMBIO_TITULARIDAD,
        apiFieldName: "gestoria",
        editFieldName: "Justificante de Gestoría",
        openFieldName: "gestoriaFile",
      },
      {
        fieldName: "Permiso de circulación",
        fileType: DocumentationAttachmentType.PERMISO_CIRCULACION,
        apiFieldName: "circulacion",
        editFieldName: "Permiso de Circulación",
        openFieldName: "circulacionFile",
      },
      {
        fieldName: "Ficha técnica",
        fileType: DocumentationAttachmentType.FICHA_TECNICA,
        apiFieldName: "fichaTecnica",
        editFieldName: "Ficha Técnica",
        openFieldName: "fichaTecnicaFile",
      },
      {
        fieldName: "Contrato",
        fieldType: DocumentationAttachmentType.CONTRATO,
        apiFieldName: "contract",
        openFieldName: "contractFile",
        editFieldName: "Contrato",
      },
    ],
    OPTIONAL: [],
  },
  AUTONOMOUS: {
    MANDATORY: [
      {
        fieldName: "DNI",
        fileType: DocumentationAttachmentType.DNI,
        apiFieldName: "dni",
        editFieldName: "Dni",
        openFieldName: "dniFile",
      },
      {
        fieldName: "Justificante de Gestoría",
        fieldType: DocumentationAttachmentType.CAMBIO_TITULARIDAD,
        apiFieldName: "gestoria",
        editFieldName: "Justificante de Gestoría",
        openFieldName: "gestoriaFile",
      },
      {
        fieldName: "Permiso de circulación",
        fileType: DocumentationAttachmentType.PERMISO_CIRCULACION,
        apiFieldName: "circulacion",
        editFieldName: "Permiso de Circulación",
        openFieldName: "circulacionFile",
      },
      {
        fieldName: "Ficha técnica",
        fileType: DocumentationAttachmentType.FICHA_TECNICA,
        apiFieldName: "fichaTecnica",
        editFieldName: "Ficha Técnica",
        openFieldName: "fichaTecnicaFile",
      },
      {
        fieldName: "Contrato",
        fieldType: DocumentationAttachmentType.CONTRATO,
        apiFieldName: "contract",
        openFieldName: "contractFile",
        editFieldName: "Contrato",
      },
      {
        fieldName: "IAE",
        fieldType: DocumentationAttachmentType.IAE,
        apiFieldName: "iae",
        editFieldName: "Certificado IAE",
        openFieldName: "iaeFile",
      },
      {
        fieldName: "Factura definitiva",
        fieldType: DocumentationAttachmentType.FACTURA_DEFINITIVA,
        apiFieldName: "facturaVenta",
        editFieldName: "Factura de Venta",
        openFieldName: "facturaVentaFile",
      },
    ],
    OPTIONAL: [],
  },
  COLLABORATING_COMPANY: {
    MANDATORY: [
      {
        fieldName: "Ficha técnica",
        fileType: DocumentationAttachmentType.FICHA_TECNICA,
        apiFieldName: "fichaTecnica",
        editFieldName: "Ficha Técnica",
        openFieldName: "fichaTecnicaFile",
      },
      {
        fieldName: "Permiso de circulación",
        fileType: DocumentationAttachmentType.PERMISO_CIRCULACION,
        apiFieldName: "circulacion",
        editFieldName: "Permiso de Circulación",
        openFieldName: "circulacionFile",
      },
      {
        fieldName: "Factura definitiva",
        fieldType: DocumentationAttachmentType.FACTURA_DEFINITIVA,
        apiFieldName: "facturaVenta",
        editFieldName: "Factura de Venta",
        openFieldName: "facturaVentaFile",
      },
    ],
    OPTIONAL: [
      {
        fieldName: "Justificante de Gestoría",
        fieldType: DocumentationAttachmentType.CAMBIO_TITULARIDAD,
        apiFieldName: "gestoria",
        editFieldName: "Justificante de Gestoría",
        openFieldName: "gestoriaFile",
      },
      {
        fieldName: "Contrato",
        fieldType: DocumentationAttachmentType.CONTRATO,
        apiFieldName: "contract",
        openFieldName: "contractFile",
        editFieldName: "Contrato",
      },
      {
        fieldName: "DNI",
        fileType: DocumentationAttachmentType.DNI,
        apiFieldName: "dni",
        editFieldName: "Dni",
        openFieldName: "dniFile",
      },
      {
        fieldName: "Factura proforma",
        fileType: DocumentationAttachmentType.FACTURA_PROFORMA,
        apiFieldName: "proforma",
        editFieldName: "Factura Proforma",
        openFieldName: "proformaFile",
      },
    ],
  },
  NO_COLLABORATING_COMPANY: {
    MANDATORY: [
      {
        fieldName: "Justificante Gestoría",
        fieldType: DocumentationAttachmentType.CAMBIO_TITULARIDAD,
        apiFieldName: "gestoria",
        editFieldName: "Justificante de Gestoría",
        openFieldName: "gestoriaFile",
      },
      {
        fieldName: "Ficha técnica",
        fileType: DocumentationAttachmentType.FICHA_TECNICA,
        apiFieldName: "fichaTecnica",
        editFieldName: "Ficha Técnica",
        openFieldName: "fichaTecnicaFile",
      },
      {
        fieldName: "Permiso circulación",
        fileType: DocumentationAttachmentType.PERMISO_CIRCULACION,
        apiFieldName: "circulacion",
        editFieldName: "Permiso de Circulación",
        openFieldName: "circulacionFile",
      },
      {
        fieldName: "Factura definitiva",
        fieldType: DocumentationAttachmentType.FACTURA_DEFINITIVA,
        apiFieldName: "facturaVenta",
        editFieldName: "Factura de Venta",
        openFieldName: "facturaVentaFile",
      },
      {
        fieldName: "CIF",
        fileType: DocumentationAttachmentType.CIF,
        apiFieldName: "cif",
        editFieldName: "Cif",
        openFieldName: "cifFile",
      },
      {
        fieldName: "Escritura",
        fileType: DocumentationAttachmentType.ESCRITURA,
        apiFieldName: "escritura",
        editFieldName: "Escritura",
        openFieldName: "escrituraFile",
      },
      {
        fieldName: "Contrato",
        fieldType: DocumentationAttachmentType.CONTRATO,
        apiFieldName: "contract",
        openFieldName: "contractFile",
        editFieldName: "Contrato",
      },
      {
        fieldName: "DNI",
        fileType: DocumentationAttachmentType.DNI,
        apiFieldName: "dni",
        editFieldName: "Dni",
        openFieldName: "dniFile",
      },
    ],
    OPTIONAL: [
      {
        fieldName: "Factura proforma",
        fileType: DocumentationAttachmentType.FACTURA_PROFORMA,
        apiFieldName: "proforma",
        editFieldName: "Factura Proforma",
        openFieldName: "proformaFile",
      },
    ],
  },
};
export default DocumentationRelevance;
