import { config } from "../config/environment.config";
import { getAccessToken } from "../store/AccessTokenStore";




export const deleteFiles = async (taskId,fileId,fileType) => {
    try {
      return fetch(
        `${config.API_HOST}/task/${taskId}/files/${fileId}?fileType=${fileType}`, 
        {   
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
        }
      ).then((resp)=>resp);
    } catch (error) {
      throw error
    }
  };

  export const sendSageRecords = async (taskId,data) => {
    try {

      const response = await fetch(
        `${config.API_HOST}/management/task/${taskId}/sage-records`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
          body: JSON.stringify(data)
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  };