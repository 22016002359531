import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import validationIBAN from "../../helpers/validationIBAN";
import { createDomain } from "../../services/domain.service";
import CustomAlert from "../Alert/CustomAlert";

import "../ModalDomain/ModalDomain.css";
import { useUserContext } from "contexts/UserContext";

const ModalDomain = ({ show, handleClose, existingDomain, task }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { user } = useUserContext();

  useEffect(() => {
    setLoading(false);
    reset({
      id: (existingDomain?.active && existingDomain?.id) || null,
      financiera:
        (existingDomain?.active && existingDomain?.financiera) || null,
      importe: (existingDomain?.active && existingDomain?.importe) || null,
      iban: (existingDomain?.active && existingDomain?.iban) || null,
      concepto: (existingDomain?.active && existingDomain?.concepto) || null,

      fechaLimite:
        (existingDomain?.active && existingDomain?.fechaLimite) || null,
      financieraFile: null,
      informeTraficoFile: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const isWithin40DaysFromToday = (value) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ajustamos a la medianoche para ignorar la hora actual
    // fecha límite (hoy + 40 días)
    const LimitDate = new Date();
    LimitDate.setDate(today.getDate() + 40);

    const inputDate = new Date(value)
    return today <= inputDate && inputDate <= LimitDate;
  };

  const onSubmitDomain = async (data) => {
    try {
      setLoading(true);
      const dataFiltered = Object.entries(data).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      const result = await createDomain({
        ...dataFiltered,
        informeTraficoFile: dataFiltered.informeTraficoFile[0],
        financieraFile: dataFiltered.financieraFile[0],
        fechaLimite: dataFiltered.fechaLimite,
        concepto: `${task?.matricula}-${dataFiltered.concepto}`,
        id_empleado: user.id,
        bastidor: task.bastidor,
        idTask: task.id,
      });
      if (result) {
        setLoading(false);
        setShowSuccess(true);
        handleClose();
      }
    } catch (error) {
      setErrorUpload(true);
      handleClose();
    }
  };

  const showAlertHandler = () => {
    setErrorUpload(false);
    setShowSuccess(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitDomain)}>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="modal_header">
            <Modal.Title>Reserva de Dominio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <h6>Datos financiera:</h6>
                <Col className="my-1">
                  <input
                    className={`form-control ${
                      errors.financiera && "is-invalid"
                    } `}
                    placeholder="Nombre"
                    {...register("financiera", { required: true })}
                  />
                  {errors.financiera?.type === "required" && (
                    <span className="error">
                      Introduza el financiera de la financiera
                    </span>
                  )}
                </Col>
                <Col className="my-1" xs={4} md={4} lg={4} xl={4}>
                  <input
                    type="number"
                    step="any"
                    className={`form-control ${
                      errors.importe && "is-invalid"
                    } `}
                    placeholder="Importe"
                    {...register("importe", { required: true })}
                  />
                  {errors.importe?.type === "required" && (
                    <span className="error">Introduzca importe</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="my-1">
                  <input
                    className={`form-control ${errors.iban && "is-invalid"} `}
                    placeholder="IBAN"
                    {...register("iban", {
                      required: true,
                      validate: (value) => validationIBAN(value),
                    })}
                  />
                  {errors.iban?.type === "required" && (
                    <span className="error">Introduzca número de IBAN</span>
                  )}
                  {errors.iban && errors.iban?.type !== "required" && (
                    <span className="error">
                      El IBAN introducido es incorrecto
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <label>Concepto:</label>
                <Col className="my-1">
                  <Row>
                    <Col xs={2} className="mt-2">
                      <span>{`${task?.matricula}-`}</span>
                    </Col>
                    <Col>
                      <input
                        className={`form-control ${
                          errors.concepto && "is-invalid"
                        } `}
                        placeholder="Concepto especificado en el pago"
                        {...register("concepto", { required: true })}
                      />
                      {errors.concepto?.type === "required" && (
                        <span className="error">Introduza el concepto</span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Fecha límite:</label>
                  <Form.Control
                    type="date"
                    {...register("fechaLimite", {
                      required: true,
                      pattern:
                        /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                      validate: isWithin40DaysFromToday,
                    })}
                    className="mb-1"
                  />
                  {errors.fechaLimite?.type === "required" && (
                    <span className="error">
                      Introduzca fecha límite para realizar el pago.
                    </span>
                  )}
                  {errors.fechaLimite &&
                    errors.fechaLimite?.type !== "required" && (
                      <span className="error">
                        Introduzca Fecha del contrato Válida
                      </span>
                    )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>Carta Financiera:</h6>
                  <input
                    accept="application/pdf"
                    type="file"
                    className={`form-control ${
                      errors.financieraFile && "is-invalid"
                    } `}
                    {...register("financieraFile", {
                      required: existingDomain ? false : true,
                    })}
                  />
                  {errors.financieraFile?.type === "required" && (
                    <span className="error">
                      Adjunte la carta de la financiera en formato pdf
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>Informe de tráfico: </h6>
                  <input
                    accept="application/pdf"
                    type="file"
                    {...register("informeTraficoFile", {
                      required: existingDomain ? false : true,
                    })}
                    className={`form-control ${
                      errors.informeTraficoFile && "is-invalid"
                    } `}
                  />
                  {errors.informeTraficoFile?.type === "required" && (
                    <span className="error">
                      Adjunte el el informe de tráfico en formato pdf
                    </span>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container fluid>
              <Row className="justify-content-between">
                <Col>
                  {!loading ? (
                    <Button
                      className="btn_custom"
                      onClick={handleSubmit(onSubmitDomain)}
                    >
                      Guardar
                    </Button>
                  ) : (
                    <Button className="btn_custom" disabled>
                      Subiendo...
                      <Spinner
                        variant="warning"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </form>

      {errorUpload && (
        <CustomAlert
          tittle="Reserva de dominio"
          message="Ha habido un problema con la creación de la reserva de dominio. Vuelva a intentarlo más tarde y en caso de que el error persista contacte con su administrador."
          variant="danger"
          showAlertHandler={showAlertHandler}
        />
      )}

      {showSuccess && (
        <CustomAlert
          tittle="Reserva de dominio"
          message="La reserva de dominio ha sido añadida con éxito."
          variant="success"
          showAlertHandler={showAlertHandler}
        />
      )}
    </div>
  );
};

export default ModalDomain;
