import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import "./TaskDetail.css";
import { getSignedUrl, getTask, updateTask } from "../../services/axiosCall";
import ModalDomain from "../../components/ModalDomain/ModalDomain";
import ModalDocumentation from "../../components/ModalDocumentation/ModalDocumentation";
import DeleteDomainModal from "../../components/DeleteModal/DeleteDomainModal";
import { TiEdit, TiDeleteOutline, TiArrowLeft } from "react-icons/ti";
import { FaRegEye } from "react-icons/fa";
import { FiAlertTriangle, FiUpload } from "react-icons/fi";

import ModalEdition from "../../components/ModalEdition/ModalEdition";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import ModalDomainEdition from "../../components/ModalDomainEdition/ModalDomainEdition";
import { updateDomain } from "../../services/domain.service";
import ModalIncidence from "../../components/ModalIncidence/ModalIncidence";
import {
  deleteIncidence,
  updateIncidence,
} from "../../services/incidence.service";
import RolType from "../../constants/roles";
import OriginType from "../../constants/origin";
import PaymentState from "../../constants/paymentState";
import DocumentationRelevance from "../../constants/documentationRelevance";
import ModalManualExport from "../../components/ModalManualExport/ModalManualExport";
import isCentralAppraisalRole from "../../utils/isCentralAppraisalRole";
import { errorToast, successToast } from "../../helpers/toastFunction";
import { deleteFiles } from "../../services/taskService";
import { API_ERRORS, getApiError } from "../AddInvoice/constants";
import { useUserContext } from "contexts/UserContext";
import { TypeTask } from "utils/constants/constants";
import { DocumentationLetter } from "constants/documentationTypes";
import validationPlate from "helpers/validationPlate";
import { EditButton } from "components/EditButton/EditButton";
import DocumentationAttachmentType from "constants/documentation";
import { SageNotice } from "components/SageNotice/SageNotice";

/* Lo que vas a encontrar en este archivo es un  código espagueti de manual. 
 Se han invertido más de 50 horas en tratar de solucionarlo,
 pero la deuda no es solo técnica, también es funcional, y nadie sabe por qué todo está así
 ¡Mucho ánimo! */

const TaskDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, socket } = useUserContext();

  const [task, setTask] = useState(null);
  const [documentType, setDocumentType] = useState(null);

  const [showManualExport, setShowManualExport] = useState(false);
  const handleShowManualExport = () => setShowManualExport(!showManualExport);

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(!showDelete);

  const [showIncidence, setShowIncidence] = useState(false);
  const handleShowIncidence = () => setShowIncidence(!showIncidence);

  const [showDomain, setShowDomain] = useState(false);
  const handleShowDomain = () => setShowDomain(!showDomain);

  const [showEdition, setShowEdition] = useState(false);
  const handleShowEdition = () => setShowEdition(!showEdition);

  const [showDomainEdition, setShowDomainEdition] = useState(false);
  const handleShowDomainEdition = () =>
    setShowDomainEdition(!showDomainEdition);

  const handleCloseDeleteDomain = () => setShowDeleteDomain(false);
  const handleShowDeleteDomain = () => setShowDeleteDomain(true);

  const [showDeleteDomain, setShowDeleteDomain] = useState(false);

  const deleteDomainHandler = () => handleShowDeleteDomain();
  const [showEditDocumentation, setShowEditDocumentation] = useState(false);

  const isGeneralOrCentralAdmin = user.roles.some(
    (rol) =>
      rol === RolType.GENERAL_ADMIN || rol === RolType.CENTRAL_ADMIN
  );
  
  const handleShowEditDocumentation = (type) => {
    if (isCentralAppraisalRole(user.roles)) {
      errorToast("No tienes permisos");
      return;
    }
    setDocumentType(type);
    setShowEditDocumentation(!showEditDocumentation);
  };

  const handleShowDeleteDocumentation = async (type, fileId, fieldName) => {
    try {
      const result = await deleteFiles(task.id, fileId, type);

      if (result.status === 204) {
        const newTask = await getTask(id);
        setTask(newTask);
        return successToast(`${fieldName} borrado con éxito`);
      }
      if (result.status === 404) {
        return errorToast("Documento no encontrado");
      }
      return errorToast(getApiError(API_ERRORS.ERR_BAD_REQUEST));
    } catch (error) {
      errorToast(getApiError(error.code));
    }
  };

  const editHandler = (type) => {
    setDocumentType(type);
    setShowEdition(!showEdition);
  };

  const editDomainHandler = (type) => {
    setDocumentType(type);
    setShowDomainEdition(!showDomainEdition);
  };

  const deleteHandler = () => {
    handleShowDelete();
  };

  const totalImportHandler = (importe, ajuste, domain) => {
    const number = (importe - (ajuste || 0) - (domain || 0)).toFixed(2);
    let str = number.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); //añade un punto cada 3 cifras
    return str.join(",");
  };

  const verifyDomainHandler = () => {
    if (!task.domain?.cartaFinanciera || !task.domain?.informeTrafico) {
      return false;
    }
    return true;
  };
 
  const checkEdition = () => {
    if (user.roles.includes(RolType.GENERAL_ADMIN)) {
      return true;
    }
    if (task.sage?.status === 1) {
      return false;
    }

    if (isCentralAppraisalRole(user.roles) || task.completed) {
      return false;
    }

    if (
      task.pago?.value === PaymentState.VALIDATED &&
      task.origin.value !== OriginType.COLLABORATING_COMPANY
    ) {
      return false;
    }

    const isAdminOrCentralAdmin = user.roles.some(
      (role) =>
        role === RolType.ADMINISTRATION || role === RolType.CENTRAL_ADMIN
    );

    if (isAdminOrCentralAdmin) {
      const isPaymentValidatedOrCompleted =
        task.domain?.estadoPago === PaymentState.VALIDATED ||
        task.domain?.estadoPago === PaymentState.COMPLETED;

      if (isPaymentValidatedOrCompleted) {
        return user.roles.includes(RolType.CENTRAL_ADMIN);
      }

      if (task.incidencia && task.pago?.value !== PaymentState.COMPLETED) {
        return true;
      }
      if (task.pago?.value === PaymentState.UNPROCESSED || task.pago === null) {
        return true;
      }
    } else {
      if (task.incidencia) {
        if (task.pago?.value !== PaymentState.COMPLETED) {
          return true;
        }
        if (task.domain?.estadoPago === PaymentState.UNPROCESSED) {
          return true;
        }
      }
    }

    return false;
  };
  
  const checkDocumentsEdition = () => {
    if (
      task.pago?.value === PaymentState.VALIDATED &&
      task.origin.value === OriginType.COLLABORATING_COMPANY
    ) {
      return true;
    }

    return checkEdition();
  };

  const checkProofDocument = () => {
    if (
      task.origin.value === OriginType.COLLABORATING_COMPANY &&
      task.sage?.status === 1 &&
      task.domain.estadoPago === PaymentState.VALIDATED
    ) {
      return true;
    }
    return checkDocumentsEdition();
  };

  const checkButtonStatus = () => {
    if (!task.isFilled) return true
    if (task.pago) {
      if (
        task.pago.value === PaymentState.VALIDATED ||
        task.pago.value === PaymentState.COMPLETED ||
        task.pago.value === PaymentState.SIGNATURE_PENDING
      ) {
        return true;
      }

      return false;
    }
  };
  const checkButtonText = () => {
    if (task.pago?.value === PaymentState.VALIDATED) {
      return "Validado";
    } else if (task.pago?.value === PaymentState.SIGNATURE_PENDING) {
      return "Pendiente de firma";
    } else if (task.pago?.value === PaymentState.COMPLETED) {
      return "Pagado";
    } else {
      if (
       isGeneralOrCentralAdmin
      ) {
        return "Validar";
      } else {
        return "Pendiente de validación";
      }
    }
  };

  const checkButtonExport = () => {
    return task.exported ? "Exportado" : "Exportar Manualmente";
  };

  const verifyFilesByOrigin = (isforExportOrDomain = false) => {
    switch (task.origin?.value) {
      case OriginType.PRIVATE:
        return (
          task.files.contractFile &&
          task.files.dniFile &&
          task.files.fichaTecnicaFile &&
          task.files.circulacionFile &&
          (isforExportOrDomain || task.files.gestoriaFile)
        );
  
      case OriginType.AUTONOMOUS:
        return (
          task.files.facturaVentaFile &&
          task.files.iaeFile &&
          task.files.contractFile &&
          task.files.dniFile &&
          task.files.fichaTecnicaFile &&
          task.files.circulacionFile &&
          (isforExportOrDomain || task.files.gestoriaFile)
        );
  
      case OriginType.COLLABORATING_COMPANY:
        return task.files.proformaFile || task.files.facturaVentaFile;
  
      case OriginType.NO_COLLABORATING_COMPANY:
        return (
          task.files.facturaVentaFile &&
          task.files.dniFile &&
          task.files.fichaTecnicaFile &&
          task.files.circulacionFile &&
          (isforExportOrDomain || task.files.gestoriaFile) && 
          task.files.cifFile &&
          task.files.escrituraFile &&
          task.files.contractFile
        );
  
      default:
        return false;
    }
  };

  const verifyHandler = () => {
    if (task.domain) {
      if (
        task.domain?.estadoPago === PaymentState.UNPROCESSED ||
        task.domain?.estadoPago === PaymentState.SIGNATURE_PENDING
      ) {
        return false;
      }
    }
    verifyFilesByOrigin();
  };

  const updateReviewHandler = async () => {
    await updateTask({
      id: task.id,
      pago: task.importe - (task.ajuste || 0) - (task.domain || 0) <= 0 ? 3 : 4,
    });
    window.location.reload(false);
  };

  const canDelete = (task) => {
    if (
      isGeneralOrCentralAdmin
    ) {
      if (
        (task.pago?.value === PaymentState.UNPROCESSED || task.pago === null) &&
        !task.domain
      ) {
        return true;
      }

      if (
        (task.pago?.value === PaymentState.UNPROCESSED || task.pago === null) &&
        task.domain
      ) {
        if (task.domain?.estadoPago === PaymentState.UNPROCESSED) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  const updateDomainReviewHandler = () => {
    const data = {
      id: task.domain.id,
      estadoPago: PaymentState.VALIDATED,
    };
    updateDomain(data).then(() => window.location.reload(false));
  };

  const getTaskHandler = useCallback(async () => {
    const response = await getTask(id);
    setTask(response);
  }, [id]);

  const handleUpdateIncidence = () => {
    if (!isCentralAppraisalRole(user.roles)) {
      updateIncidence({
        id: task.incidencia.id,
        review: !task.incidencia?.review,
      });
      return;
    }
    errorToast("No tienes permisos");
  };
  const openDocumentHandler = async (data) => {
    const response = await getSignedUrl(data);
    window.open(response.signedUrl[0]);
  };

  const getDocumentation = (type) => {
    let mandatoryArray = [];
    let optionalArray = [];
    if (type === OriginType.PRIVATE) {
      mandatoryArray = DocumentationRelevance.PRIVATE.MANDATORY;
      optionalArray = DocumentationRelevance.PRIVATE.OPTIONAL;
    } else if (type === OriginType.AUTONOMOUS) {
      mandatoryArray = DocumentationRelevance.AUTONOMOUS.MANDATORY;
      optionalArray = DocumentationRelevance.AUTONOMOUS.OPTIONAL;
    } else if (type === OriginType.COLLABORATING_COMPANY) {
      mandatoryArray = DocumentationRelevance.COLLABORATING_COMPANY.MANDATORY;
      optionalArray = DocumentationRelevance.COLLABORATING_COMPANY.OPTIONAL;
    } else if (type === OriginType.NO_COLLABORATING_COMPANY) {
      mandatoryArray =
        DocumentationRelevance.NO_COLLABORATING_COMPANY.MANDATORY;
      optionalArray = DocumentationRelevance.NO_COLLABORATING_COMPANY.OPTIONAL;
    }
    return (
      <div>
        <Row
          className={`m-3 border rounded ${task.incidencia && "incidencia"}`}
        >
          <h3 className="my-3">Documentación obligatoria</h3>
          {React.Children.toArray(
            mandatoryArray.map((item) => (
              <Col xs={3} className="my-3 d-inline-flex">
                <Row>
                  <h6 className="my-2">{item.fieldName}:</h6>{" "}
                </Row>
                <Row>
                  {task.files[`${item.openFieldName}`] ? (
                    <Col className="mb-3">
                      <FaRegEye
                        size={30}
                        color={"#007bff"}
                        className="cursor_pointer mx-2"
                        title={`Visualizar ${item.fieldName}`}
                        onClick={() =>
                          task.files[`${item.openFieldName}`] &&
                          openDocumentHandler(
                            task.files[`${item.openFieldName}`]
                          )
                        }
                      />

                      {item.fieldType ===
                      DocumentationAttachmentType.CAMBIO_TITULARIDAD ? (
                        checkProofDocument() ? (
                          <>
                            <TiEdit
                              size={30}
                              color={"#E88A00"}
                              className="cursor_pointer mx-2"
                              title={`Editar ${item.editFieldName}`}
                              onClick={() =>
                                openDocumentHandler(`${item.editFieldName}`)
                              }
                            />
                            {user.roles.includes(RolType.GENERAL_ADMIN) && (
                              <TiDeleteOutline
                                size={30}
                                color={"RED"}
                                className="cursor_pointer mx-1"
                                title={`Eliminar ${item.fieldName}`}
                                onClick={() =>
                                  handleShowDeleteDocumentation(
                                    `${item.openFieldName}`,
                                    task.files[`${item.openFieldName}`],
                                    item.fieldName
                                  )
                                }
                              />
                            )}
                          </>
                        ) : (
                          <TiEdit
                            size={30}
                            color={"#D3D3D3"}
                            className="mx-2"
                          />
                        )
                      ) : 
                      checkDocumentsEdition() ? (
                        <>
                          <TiEdit
                            size={30}
                            color={"#E88A00"}
                            className="cursor_pointer mx-2"
                            title={`Editar ${item.editFieldName}`}
                            onClick={() =>
                              handleShowEditDocumentation(
                                `${item.editFieldName}`
                              )
                            }
                          />
                          {user.roles.includes(RolType.GENERAL_ADMIN) && (
                            <TiDeleteOutline
                              size={30}
                              color={"RED"}
                              className="cursor_pointer mx-1"
                              title={`Eliminar ${item.fieldName}`}
                              onClick={() =>
                                handleShowDeleteDocumentation(
                                  `${item.openFieldName}`,
                                  task.files[`${item.openFieldName}`],
                                  item.fieldName
                                )
                              }
                            />
                          )}
                        </>
                      ) : (
                        <TiEdit size={30} color={"#D3D3D3"} className="mx-2" />
                      )}
                    </Col>
                  ) : (
                    <FiUpload
                      onClick={() =>
                        handleShowEditDocumentation(`${item.editFieldName}`)
                      }
                      size={30}
                      color={"#E88A00"}
                      className=" mx-1 cursor_pointer"
                      title={`adjuntar ${item.fieldName}`}
                    />
                  )}
                </Row>
              </Col>
            ))
          )}
        </Row>
        {optionalArray.length > 0 && (
          <Row
            className={`m-3 border rounded ${task.incidencia && "incidencia"}`}
          >
            <h3 className="my-3">Documentación opcional</h3>
            {React.Children.toArray(
              optionalArray.map((item) => (
                <Col className="m-3 d-inline-flex">
                  <Row>
                    <Col xs={11}>
                      <h6 className="m-2">{item.fieldName}:</h6>{" "}
                    </Col>
                    <Col></Col>
                  </Row>
                  {task.files[`${item.openFieldName}`] ? (
                    <Col className="">
                      <FaRegEye
                        size={30}
                        color={"#007bff"}
                        className="cursor_pointer mx-2"
                        title={`Visualizar ${item.fieldName}`}
                        onClick={() =>
                          task.files[`${item.openFieldName}`] &&
                          openDocumentHandler(
                            task.files[`${item.openFieldName}`]
                          )
                        }
                      />
                      {checkDocumentsEdition() ? (
                        <>
                          {item.fieldType !==
                            DocumentationAttachmentType.CONTRATO && (
                            <>
                              <TiEdit
                                size={30}
                                color={"#E88A00"}
                                className="cursor_pointer mx-2"
                                title={`Editar ${item.editFieldName}`}
                                onClick={() =>
                                  handleShowEditDocumentation(
                                    `${item.editFieldName}`
                                  )
                                }
                              />
                              {user.roles.includes(RolType.GENERAL_ADMIN) && (
                                <TiDeleteOutline
                                  size={30}
                                  color={"RED"}
                                  className="cursor_pointer mx-1"
                                  title={`Eliminar ${item.fieldName}`}
                                  onClick={() =>
                                    handleShowDeleteDocumentation(
                                      `${item.openFieldName}`,
                                      task.files[`${item.openFieldName}`],
                                      item.fieldName
                                    )
                                  }
                                />
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <TiEdit size={30} color={"#D3D3D3"} className="mx-2" />
                      )}
                    </Col>
                  ) : (
                    <FiUpload
                      onClick={() =>
                        handleShowEditDocumentation(`${item.editFieldName}`)
                      }
                      size={30}
                      color={"#E88A00"}
                      className=" mx-1 cursor_pointer"
                    />
                  )}
                </Col>
              ))
            )}
          </Row>
        )}
      </div>
    );
  };

  const backForward = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (socket) {
      socket.on("update", getTaskHandler);
      return () => {
        socket.off("update");
      };
    }
  }, [socket, getTaskHandler]);

  useEffect(() => {
    (async () => {
      const result = await getTask(id);
      setTask(result);
    })();
  }, [id]);


  return task ? (
    <>
      <Container fluid className="main-container">
        <Row>
          <Col className="col-12">
            <div className={`clearfix my-3 containerTitle`}>
              <span className="tittle">
                {`Gestión #${task.id}`} - ID
                {task.contract
                  ? `${DocumentationLetter[task.contract?.type]} ${
                      task.contract?.id
                    }`
                  : ""}
              </span>
            </div>
          </Col>
        </Row>

        <Button onClick={backForward} className="over_screen_down_left">
          <TiArrowLeft size={30} className="cursor_pointer" />
          <span>Volver</span>
        </Button>

        {isGeneralOrCentralAdmin && (
          <Row className="m-2">
            <Col>
              {verifyHandler() && (
                <Button
                  className="custom_button_width"
                  variant={
                    task.pago === null || task.pago.value === 1
                      ? "outline-success"
                      : "success"
                  }
                  onClick={updateReviewHandler}
                  disabled={checkButtonStatus()}
                >
                  {checkButtonText()}
                </Button>
              )}
            </Col>

            {verifyDomainHandler() && (
              <Col>
                <Button
                  className=" custom_button_width"
                  variant={
                    task.domain?.estadoPago !== PaymentState.UNPROCESSED
                      ? "success"
                      : "outline-success"
                  }
                  onClick={updateDomainReviewHandler}
                  disabled={
                    task.domain?.estadoPago !== PaymentState.UNPROCESSED
                      ? true
                      : isGeneralOrCentralAdmin
                      ? false
                      : true
                  }
                >
                  {task.domain?.estadoPago !== PaymentState.UNPROCESSED
                    ? "R. Dominio Validada"
                    : isGeneralOrCentralAdmin
                    ? "Validar Reserva de Dominio"
                    : "R. Dominio Pendiente de Validación"}
                </Button>
              </Col>
            )}
            <Col>
              {!task.incidencia && isGeneralOrCentralAdmin && (
                <Button
                  className="custom_button_width"
                  variant={task?.incidencia ? "danger" : "outline-danger"}
                  onClick={() => handleShowIncidence()}
                >
                  Añadir incidencia
                </Button>
              )}
            </Col>
            <Col>
              {isGeneralOrCentralAdmin && verifyFilesByOrigin(true) && (
                <Button
                  className="custom_button_width"
                  variant={task.exported ? "outline-primary" : "primary"}
                  onClick={() => handleShowManualExport()}
                  disabled={task.exported || !task.isFilled}
                >
                  {checkButtonExport()}
                </Button>
              )}
            </Col>
            {!task.incidencia && (
              <Col>
                {task.domain &&
                task.domain.active &&
                task.domain?.estadoPago === PaymentState.UNPROCESSED &&
                isGeneralOrCentralAdmin ? (
                  <Button
                    className="custom_button_width"
                    onClick={() => deleteDomainHandler(task.domain.id)}
                    variant="danger"
                  >
                    Eliminar Reserva de Dominio
                  </Button>
                ) : (
                  (task.pago?.value === PaymentState.UNPROCESSED ||
                    task.pago === null) &&
                  !task.domain &&
                  verifyFilesByOrigin(true) && (
                    <Button
                      className="btn_custom custom_button_width"
                      title="La reserva de dominio es opcional y se puede añadir más tarde."
                      onClick={() => handleShowDomain()}
                    >
                      Añadir Reserva de Dominio
                    </Button>
                  )
                )}
              </Col>
            )}

            {canDelete(task) && (
              <Col>
                <Button
                  variant="danger"
                  className="custom_button_width"
                  onClick={deleteHandler}
                >
                  Eliminar tarea
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Container>
      <Container fluid>
        <Row className="m-3">
          <SageNotice sage={task.sage} />
        </Row>
        {task.incidencia && (
          <Row
            className={`m-3 border rounded ${task.incidencia && "incidencia"}`}
          >
            <Row>
              <Col xl={10}>
                <h4 className="my-3">{task.incidencia?.tittle}</h4>
              </Col>
              {isGeneralOrCentralAdmin && (
                <Col>
                  <Button
                    className="custom_button_width my-3"
                    variant={task.incidencia?.review ? "success" : "danger"}
                    onClick={() => deleteIncidence(task.id)}
                  >
                    {task.incidencia?.review
                      ? "Resolver incidencia"
                      : "Cerrar incidencia"}
                  </Button>
                </Col>
              )}

              {user.roles.includes(RolType.ADMINISTRATION) && (
                <Col>
                  <Button
                    className="custom_button_width my-3"
                    variant={
                      task?.incidencia?.review ? "danger" : "outline-danger"
                    }
                    onClick={() => handleUpdateIncidence()}
                  >
                    {task.incidencia?.review
                      ? "Cancelar resolución"
                      : "Solicitar resolución"}
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <h5>{task.incidencia?.description}</h5>
            </Row>
          </Row>
        )}
        <Row
          className={`m-3 border rounded ${task.incidencia && "incidencia"}`}
        >
          {!task.isFilled && (
            <Row className="wargning-box">
              <h6>
                <FiAlertTriangle size={20} className="me-3 wargning" />
                Los campos en rojo son obligatorios para poder gestionar la
                operación
              </h6>
            </Row>
          )}
          <Row>
            <Col>
              <h3 className="my-3">Gestión</h3>
              <h6 className={task.gestion ? "" : "wargning"}>
                {"Tipo: "}
                <strong>
                  {task.gestion?.label || "-"}
                  <EditButton
                    checkVisibility={checkEdition()}
                    onClick={() => editHandler("gestion")}
                  />
                </strong>
              </h6>
              <h6 className={task.tramite ? "" : "wargning"}>
                {"Tramite: "}
                <strong>
                  {task?.tramite?.label || "-"}
                  <EditButton
                    checkVisibility={checkEdition()}
                    onClick={() => editHandler("tramite")}
                  />
                </strong>
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="my-3">Cuentas</h3>
              <h6>Vehículo: {totalImportHandler(task.importe)} €</h6>
              {task?.gestion?.value === TypeTask.Forma_de_pago && (
                <h6 className={task.vehiculoFormaPago ? "" : "wargning"}>
                  Vehiculo forma pago: {task.vehiculoFormaPago}
                  <EditButton
                    checkVisibility={checkEdition()}
                    onClick={() => editHandler("vehiculoFormaPago")}
                  />
                </h6>
              )}
              <h6 className={typeof task.ajuste === "number" ? "" : "wargning"}>
                Ajuste: {typeof task.ajuste === "number" ? task.ajuste : ""} €{" "}
                <EditButton
                  checkVisibility={checkEdition()}
                  onClick={() => editHandler("ajuste")}
                />
              </h6>
              <h6>
                R. dominio:
                {task.domain && task.domain.active ? task.domain.importe : 0} €
              </h6>
              <h6>
                Importe a pagar:
                <strong>
                  {totalImportHandler(
                    task.importe,
                    task.ajuste,
                    task.domain?.importe
                  )}
                </strong>
                €
              </h6>
              {task.fechaPago && (
                <h6>
                  Fecha de pago: {task.fechaPago.split("-").reverse().join("/")}
                </h6>
              )}
              <h3 className="mt-5">Concesionario</h3>
              <h6>Local: {task.local?.label}</h6>
              <h6>Sociedad: {task.society.label}</h6>
              <h6>
                Gestoria: {task.gestoria ? task.gestoria.name : "Ninguna"}
              </h6>
            </Col>
            <Col>
              <h3 className="my-3">Vehículo</h3>

              <h6>Marca: {task.marca}</h6>
              <h6>Modelo: {task.modelo}</h6>
              <h6>Matrícula: {task.matricula}</h6>
              <h6>
                Vehiculo Importado :{" "}
                {validationPlate(task.matricula) ? "No" : "Si"}
              </h6>
              <h6>Rematriculación: {task.newPlate || "-"}</h6>
              <h6>Bastidor: {task.bastidor}</h6>
              <h6 className={task.matriculacion ? "" : "wargning"}>
                1ª Matriculación:
                {task.matriculacion
                  ? task.matriculacion
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")
                  : ""}
                <EditButton
                  checkVisibility={checkEdition()}
                  onClick={() => editHandler("matriculacion")}
                />
              </h6>
              <h6>
                Fecha Contrato:
                {task.fechaContrato
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/")}
              </h6>
              <h6>
                Fecha Factura:
                {task.fechaFactura
                  ? task.fechaFactura
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")
                  : "-"}
              </h6>
            </Col>
            <Col>
              <h3 className="my-3">Cliente</h3>

              <h6>Nombre: {task.titular}</h6>
              <h6>
                {task?.origin?.value === PaymentState.UNPROCESSED ||
                task?.origin?.value === PaymentState.SIGNATURE_PENDING
                  ? "Dni"
                  : "CIF"}
                : {task.numeroIdentificacion}
              </h6>
              <h6 className={task.origin ? "" : "wargning"}>
                Origen: {task?.origin?.label || ""}
                <EditButton
                  checkVisibility={checkEdition()}
                  onClick={() => editHandler("origen")}
                />
              </h6>
              <h6>Dirección: {task.direccion}</h6>
              <h6>Código Postal: {task.zip}</h6>
              <h6 className={task.iban ? "" : "wargning"}>
                IBAN: {task.iban || "-"}
                <EditButton
                  checkVisibility={checkEdition()}
                  onClick={() => editHandler("iban")}
                />
              </h6>
              <h6 className={task.impuestos ? "" : "wargning"}>
                Impuestos: {task?.impuestos?.label || ""}
                <EditButton
                  checkVisibility={checkEdition()}
                  onClick={() => editHandler("impuestos")}
                />
              </h6>
            </Col>

            {task.domain && task.domain.active && (
              <Col>
                <h3 className="my-3">Reserva de Dominio</h3>
                <Row>
                  <h6>
                    Financiera: {task.domain.financiera}{" "}
                    <EditButton
                      checkVisibility={checkEdition()}
                      onClick={() => editDomainHandler("financiera")}
                    />
                  </h6>
                  <h6>
                    Importe: {task.domain.importe} €{" "}
                    <EditButton
                      checkVisibility={checkEdition()}
                      onClick={() => editDomainHandler("importe")}
                    />
                  </h6>
                  <h6>
                    IBAN: {task.domain.iban}
                    <EditButton
                      checkVisibility={checkEdition()}
                      onClick={() => editDomainHandler("iban")}
                    />
                  </h6>
                  <h6>
                    Concepto: {task.domain.concepto}{" "}
                    <EditButton
                      checkVisibility={checkEdition()}
                      onClick={() => editDomainHandler("concepto")}
                    />
                  </h6>
                  <h6>
                    Fecha límite:{" "}
                    {task.domain.fechaLimite
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}{" "}
                    <EditButton
                      checkVisibility={checkEdition()}
                      onClick={() => editDomainHandler("fecha")}
                    />
                  </h6>

                  {task.domain?.fechaPago && (
                    <h6>
                      Fecha de pago:{" "}
                      {task.domain?.fechaPago.split("-").reverse().join("/")}
                    </h6>
                  )}

                  <Row className="mt-4">
                    <Col>
                      Carta Financiera:{" "}
                      <FaRegEye
                        size={30}
                        color={"#007bff"}
                        className="cursor_pointer mx-2"
                        title="Visualizar Carta Financiera"
                        onClick={() =>
                          task.domain.cartaFinanciera &&
                          openDocumentHandler(task.domain.cartaFinanciera)
                        }
                      />
                      <EditButton
                        checkVisibility={checkEdition()}
                        onClick={() => editDomainHandler("FinancieraFile")}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-5">
                    <Col>
                      Informe de tráfico:
                      <FaRegEye
                        size={30}
                        color={"#007bff"}
                        className="cursor_pointer mx-2 "
                        title="Visualizar Informe de tráfico"
                        onClick={() =>
                          task.domain.informeTrafico &&
                          openDocumentHandler(task.domain.informeTrafico)
                        }
                      />
                      <EditButton
                        checkVisibility={checkEdition()}
                        onClick={() => editDomainHandler("informeTraficoFile")}
                      />
                    </Col>
                  </Row>
                </Row>
              </Col>
            )}
          </Row>
        </Row>
        {getDocumentation(task.origin?.value)}
      </Container>
      <ModalDomain
        show={showDomain}
        handleClose={handleShowDomain}
        existingDomain={null}
        task={task}
      />
      <ModalDocumentation
        show={showEditDocumentation}
        handleClose={handleShowEditDocumentation}
        existingTask={task}
        type={documentType}
      />
      <DeleteDomainModal
        show={showDeleteDomain}
        handleClose={handleCloseDeleteDomain}
        domain={task.domain}
        taskId={task.id}
      ></DeleteDomainModal>
      <ModalEdition
        show={showEdition}
        handleClose={handleShowEdition}
        task={task}
        type={documentType}
      ></ModalEdition>
      <ModalDomainEdition
        show={showDomainEdition}
        handleClose={handleShowDomainEdition}
        domain={task?.domain}
        type={documentType}
      ></ModalDomainEdition>
      <ModalIncidence
        show={showIncidence}
        handleClose={handleShowIncidence}
        task={task}
      ></ModalIncidence>
      <DeleteModal
        show={showDelete}
        handleClose={handleShowDelete}
        task={task}
      ></DeleteModal>
      {task && (
        <ModalManualExport
          show={showManualExport}
          handleClose={handleShowManualExport}
          task={task}
        ></ModalManualExport>
      )}
    </>
  ) : (
    <h6>Cargando...</h6>
  );
};

export default TaskDetail;
